import 'babel-polyfill';
import Vue from 'vue';
import './plugins/vuetify';
import './plugins/vue-auth';
import './plugins/filters';
import App from './App.vue';
import router from './router/';
import store from './store';
import Vuetify from 'vuetify';
import vuetify from '@/plugins/vuetify';
import VuetifyConfirm from 'vuetify-confirm'
import moment from 'moment';
import './scss/fonts.scss';
import './scss/variables.scss';
import './scss/cards.scss';
import './scss/dataTables.scss';

import { mask } from 'vue-the-mask';
Vue.directive('mask', mask);

Vue.use(Vuetify, {
  iconfont: 'fa',
});

Vue.use(VuetifyConfirm, { vuetify,
  buttonTrueText: 'Sim',
  buttonFalseText: 'Não', 
})

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
