<template>
  <nav>
    <v-app-bar
      id="appBar"
      color="white"
      fixed
      clipped-left
      flat
      dense
      app
      max-height="100"
      height="100"
      class="align-center"
    >
      <div class="menu-button-container d-flex align-center">
        <v-app-bar-nav-icon
          class="button-icon mr-2"
          @click="handleDrawerToggle"
        ></v-app-bar-nav-icon>
        <span class="text-uppercase title">menu</span>
      </div>
      <img
        src="/static/logo-ecotrace-horizontal.png"
        width="196"
        height="auto"
        class="ml-8 mr-9"
      />
      <v-toolbar-title
        class="font-italic font-weight-light text-uppercase title"
        >TRACE BEEF B2B</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <!-- <v-btn class="ma-2 font-italic font-weight-regular subtitle-1 text-capitalize" height="auto" text color="#707070"> 
        <v-avatar size="38" class="mr-3">
          <v-img src="/static/icon.png" contain width="38" height="38"></v-img>
        </v-avatar>
        Ecotrace{{this.$auth.user().name}}
        <v-icon color="primary" class="ml-5" size="18" right>fas fa-caret-down</v-icon>
      </v-btn>
      <v-divider
        class="mx-2"
        inset
        vertical
        style="max-height: 48px; margin-top: 0; align-self: center;"
      ></v-divider> -->
      <!-- <v-btn text icon color="secondary" class="ml-5">
        <v-icon size="25">far fa-bell</v-icon>
      </v-btn>
      <v-btn text icon color="secondary" class="ml-5">
        <v-icon size="25">far fa-question-circle</v-icon>
      </v-btn> -->
      <Button
        class="ml-12"
        size="medium"
        text="sair"
        :loading="loading"
        color="secondary"
        @do="logout"
      />
    </v-app-bar>
    <NavigationDrawer :drawer="drawer" />
  </nav>
</template>

<script>
import Button from '@/components/buttons/Button';
import NavigationDrawer from '@/components/NavigationDrawer';
export default {
  components: {
    Button: Button,
    NavigationDrawer: NavigationDrawer,
  },

  data: () => ({
    drawer: false,
    loading: false,
  }),
  mounted() {},
  methods: {
    logout() {
      this.$auth.logout({
        makeRequest: true,
        redirect: '/login',
        success: function() {
          localStorage.clear();
        },
        error: function() {
          localStorage.clear();
        },
      });
    },
    handleDrawerToggle() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style lang="scss" scoped>
#appBar::v-deep {
  color: $primary-color;
  z-index: 20;

  .menu-button-container {
    color: #abafb3;

    .v-btn--round:before,
    .v-btn--rounded:before {
      border-radius: 3px;
    }

    .button-icon {
      color: $secondary-color;
    }

    .v-btn--icon.v-size--default .v-icon {
      height: 46px;
    }
  }
}
</style>
