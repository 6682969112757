export default [
  {
    path: '/',
    meta: {},
    name: 'Root',
    title: 'Rastreabilidade',
    redirect: {
      name: 'Industries',
    },
  },
  {
    path: '/login',
    meta: {
      public: true,
    },
    name: 'Login',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/Login.vue`
      ),
  },

  {
    path: '/industrias',
    meta: { breadcrumb: true, auth: true },
    title: 'Indústrias',
    name: 'Industries',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/Industries/Index.vue`
      ),
  },
  {
    path: '/industrias/new',
    meta: { breadcrumb: true, auth: true },
    title: 'Indústria',
    name: 'IndustryNew',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/Industries/Industry.vue`
      ),
  },
  {
    path: '/industrias/:id',
    meta: { breadcrumb: true, auth: true },
    title: 'Indústria',
    name: 'IndustryEdit',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/Industries/Industry.vue`
      ),
  },
  {
    path: '/usuarios',
    meta: { breadcrumb: true, auth: true },
    title: 'Usuários',
    name: 'Users',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/Users/Index.vue`
      ),
  },

  {
    path: '/usuarios/new',
    meta: { breadcrumb: true, auth: true },
    title: 'Usuário',
    name: 'UserNew',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/Users/User.vue`
      ),
  },

  {
    path: '/usuarios/:id',
    meta: { breadcrumb: true, auth: true },
    title: 'Usuário',
    name: 'UserEdit',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/Users/User.vue`
      ),
  },

  {
    path: '/produtos',
    meta: { breadcrumb: true, auth: true },
    title: 'Listagem de Produtos',
    name: 'Products',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/Products/Index.vue`
      ),
  },

  {
    path: '/produtos/new',
    meta: { breadcrumb: true, auth: true },
    title: 'Criação de Produto',
    name: 'ProductNew',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/Products/Product.vue`
      ),
  },

  {
    path: '/produtos/:id',
    meta: { breadcrumb: true, auth: true },
    title: 'Edição de Produto',
    name: 'ProductEdit',
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/Products/Product.vue`
      ),
  },
];
