import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

// Translation provided by Vuetify (typescript)
import pt from 'vuetify/src/locale/pt.ts'
import en from 'vuetify/src/locale/en.ts'

export default new Vuetify({
  icons: {
    iconfont: 'fa4'
  },
  lang: {
    locales: {pt, en},
    current: 'pt',
  },
  theme: {
    themes: {
      light: {
        primary: '#3FAB36',
        secondary: '#315CA7',
        tertiary: '#F2F3F7',
        quaternary: '#6A707E',
        quinternary: '#81C683'
      },
      dark: {
        primary: '#3FAB36',
        secondary: '#315CA7',
        tertiary: '#F2F3F7',
        quaternary: '#6A707E',
        quinternary: '#81C683'
      },
    },
  },
})