<template>
  <v-btn
    :class="
      size ? 'ecoButton text-center' + ' ' + size : 'ecoButton text-center'
    "
    :color="color"
    :to="linkTo"
    :text="justText"
    :icon="icon"
    :iconText="iconText"
    :absolute="absolute"
    :fixed="fixed"
    :top="top"
    :bottom="bottom"
    :left="left"
    :right="right"
    :loading="loading"
    :large="large"
    :type="type"
    @click="doClick"
    dark
  >
    <v-icon v-if="icon">{{ iconText }}</v-icon>
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: {
    text: String,
    iconText: String,
    icon: Boolean,
    size: String,
    color: String,
    linkTo: Object,
    do: String,
    loading: Boolean,
    justText: Boolean,
    absolute: Boolean,
    fixed: Boolean,
    top: Boolean,
    bottom: Boolean,
    left: Boolean,
    right: Boolean,
    large: Boolean,
    type: String,
  },

  methods: {
    doClick() {
      this.$emit('do');
    },
  },
};
</script>

<style lang="scss" scoped>
.ecoButton::v-deep {
  height: auto !important;
  border-radius: 42px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0px 17px 31px #00000024;

  span.v-btn__content {
    white-space: normal;
    letter-spacing: 0;
  }
}

.ecoButton::v-deep.v-btn--text {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 10px 20px;

  span.v-btn__content {
    text-transform: initial;
  }
}

.ecoButton::v-deep.text--underlined,
.ecoButton::v-deep.underlined {
  span.v-btn__content {
    text-decoration: underline;
  }
}

.ecoButton.big {
  max-width: 395px;
  padding: 20px 75px;
  font-size: 18px;
}

.ecoButton.medium {
  padding: 11px 34px;
  font-size: 16px;
}

.ecoButton.small {
  padding: 9px 29px;
  font-size: 13px;
}

.ecoButton.primary {
  background-color: $primary-color !important;
}

.ecoButton.secondary {
  background-color: $secondary-color !important;
}

.ecoButton.secondary--text {
  color: $secondary-color !important;
  caret-color: $secondary-color !important;
}

.ecoButton.tertiary {
  color: black;
  background-color: $tertiary-color !important;
}

.ecoButton.quaternary {
  background-color: $quaternary-color !important;
}

.ecoButton.quinternary {
  background-color: $quinternary-color !important;
}
</style>
