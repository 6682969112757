<template>
  <v-footer
    color="teal darken-1"
    dark
    app
  >
      <span>&copy; 2019</span>
  </v-footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>