import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDateHour', function(value) {
  if (moment(value).isValid()) {
    return moment(value).format('DD/MM/YYYY HH:mm');
  }
  return '';
});

Vue.filter('formatDate', function(value) {
  if (moment(value).isValid()) {
    return moment(value).format('DD/MM/YYYY');
  }
  return '';
});

Vue.filter('formatHour', function(value) {
  if (moment(value).isValid()) {
    return moment(value).format('HH:mm');
  }
  return '';
});

Vue.filter('formatDateByISO', function(value) {
  if (moment(value).isValid()) {
    return moment(value)
      .add(3, 'hours')
      .format('DD/MM/YYYY');
  }
  return '';
});

Vue.filter('formatDateHourByISO', function(value) {
  if (moment(value).isValid()) {
    return moment
      .parseZone(value)
      .format('DD/MM/YYYY HH:mm');
  }
  return '';
});

Vue.filter('formatCpf', function(value) {
  if (!value) return '';
  value = value.toString().replace(/\D/g, '');
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
});

Vue.filter('formatCpfCnpj', function(value) {
  if (!value) return '';
  value = value.toString().replace(/\D/g, '');
  if (value.length === 11) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else {
    return value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  }
});

Vue.filter('capitalize', function(value) {
  if (!value) return '';
  value = value || '';
  value = value.toLowerCase();

  return value
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
});

Vue.filter('formatPhone', function(number) {
  if (number.length === 10) {
    return `(${number.substring(0, 2)}) ${number.substring(2, 6)} -
      ${number.substring(6, 10)}`;
  }
  if (number.length === 11) {
    return `(${number.substring(0, 2)}) ${number.substring(2, 7)} -
    ${number.substring(7, 11)}`;
  } else {
    return number;
  }
});
