const Menu = [
  {
    title: 'Indústrias',
    group: 'industries',
    icon: 'fas fa-filter',
    name: 'Industries',
    profiles: ['eco_admin', 'group_admin'],
  },
  {
    title: 'Usuários',
    group: 'users',
    icon: 'fas fa-users',
    name: 'Users',
    profiles: ['eco_admin', 'group_admin'],
  },
  {
    title: 'Produtos',
    group: 'products',
    icon: 'fas fa-box-open',
    name: 'Products',
    profiles: ['eco_admin', 'group_admin'],
  },
];
// reorder menu
Menu.forEach((item) => {
  if (item.items) {
    item.items.sort((x, y) => {
      let textA = x.title.toUpperCase();
      let textB = y.title.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }
});

export default Menu;
