import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showBottomMenu : true,
    alerts: 0
  },
  mutations: {},
  actions: {}
});